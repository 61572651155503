.main-header-logo {
  max-width: 100%;
  height: auto;
}

.overflow-text-length-menu {
  display: inline-block !important;
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.more-width-menu {
  width: 254px !important;
}

.more-width-menu-2 {
  width: 287px !important;
}

.hover-menu:hover {
  /* background-color: hsl(188, 69%, 94%) !important */
  background-color: #36491d08 !important;
  /* background-color: #49691F !important */
}

.kt-aside-menu {
  background: white !important;
  /* border-right: 3px solid gray; */
  /* 
    text-align:center;
    padding-bottom:5px;
      linear-gradient(
        to left, 
        rgba(92,7,52,1) 0%,
        rgba(134,29,84,1) 12%,
        rgba(255,93,177,1) 47%,
        rgba(83,0,30,1) 100%
      )
      left 
      bottom
      #777    
      no-repeat; 
    background-size:100% 5px ; */
}

.kt-header {
  /* text-align:center; */
  padding-bottom: 5px;
  background:
        /* gradient can be an image */ linear-gradient(
      to left,
      #49691f 0%,
      #fd9002 12%,
      #49691f 67%,
      #fd9002 100%
    )
    left bottom white no-repeat;
  background-size: 100% 5px;
  /* if linear-gradient, we need to resize it */
}

.kt-aside__brand {
  padding-bottom: 5px;
  background:
        /* gradient can be an image */ linear-gradient(
      to left,
      #fd9002 0%,
      #fd9002 12%,
      #fd9002 47%,
      #fd9002 100%
    )
    left bottom white no-repeat;
  background-size: 100% 5px;
  /* if linear-gradient, we need to resize it */
}

.kt-aside {
  background-color: white !important;
}

.kt-aside__brand {
  height: 79px !important;
}

.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link {
  background-color: #36491d !important;
}

.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-icon,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-icon {
  color: #fd9002;
}

.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link {
  background-color: #36491d !important;
}

.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-icon,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-icon {
  color: #fd9002;
}

.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__link {
  background-color: #36491d !important;
}

.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__link-icon,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-icon {
  color: #fd9002;
}

.kt-badge.kt-badge--unified-success {
  color: #36491d !important;
  background: #36491d00 !important;
}

/* max-height: 300px; */
.kt-menu__submenu .kt-menu__submenu--left .kt-menu__submenu--classic {
  max-height: 300px;
  overflow-y: auto;
}

.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu.kt-menu__submenu--fixed {
  max-height: 380px !important;
}

.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu.kt-menu__submenu--right {
  right: auto !important;
  left: auto !important;
}

.header-user-name-length {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  color: #36491d !important;
}

.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-user
  .kt-badge.kt-badge--username {
  width: auto;
  max-width: 130px !important;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-text {
  color: #49691f;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-text {
  color: #49691f;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-text {
  color: #49691f;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-text {
  color: #49691f;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__item-here,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__item-here {
  color: #49691f;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-text {
  color: #49691f;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-text {
  color: #49691f;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__ver-arrow,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__ver-arrow {
  color: #49691f;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet,
.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-bullet
  > span {
  background-color: #49691f !important;
}

.lang-box-style,
.lang-box-style-ar {
  border: 2px solid #fd9002;
  padding: 8px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  color: #49691f;
  font-weight: bold;
}

.lang-box-style-ar {
  padding-right: 15px;
  padding-left: 15px;
}

.filter-box-inv-style {
  padding-left: 20px;
  padding-right: 29px;
}

.filter-box-style-inv {
  background-color: #8080800d;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.lang-box-style-menu {
  color: #49691f;
  font-weight: bold;
  border-right: 10px solid;
}

.border-radius {
  border-radius: 7px;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.dropdown-inv,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-menu-inv.show {
  top: 109% !important;
}

.dropdown-menu-inv.show {
  display: block;
}

.dropdown-menu-inv[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

.dropdown-menu-inv {
  padding: 0.39rem;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  -webkit-animation-name: DropDownSlide;
  animation-name: DropDownSlide;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  margin: 0;
  border: none;
  font-size: 0.875rem;
  position: absolute;
  z-index: 1000;
}

.dropdown-item-inv {
  display: block;
  width: 100%;
  padding: 0.375rem 1.2rem;
  clear: both;
  font-weight: 400;
  /* color: #6c757d; */
  color: #74788d;
  text-align: inherit;
  white-space: nowrap;
  background-color: initial;
  border: 0;
}

.dropdown-menu-inv {
  top: 100%;
  /* left: 0; */
  display: none;
  float: left;
  min-width: 10rem;
  /* padding: 0.25rem 0; */
  margin: 0.125rem 0 0;
  font-size: 12px;
  color: #74788d;
  list-style: none;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #e7eef1;
  border-radius: 0.25rem;
}

.dropdown-item-inv:hover {
  background-color: #36491d08 !important;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon,
.kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon {
  background-color: #f2f3f700 !important;
}

.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-icon,
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-text,
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-arrow,
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-icon,
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-text,
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-arrow,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-icon,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-text,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-arrow {
  color: #49691f !important;
}

label.btn.btn-label-brand:focus,
label.btn.btn-label-brand:hover,
label.btn.btn-label-brand.active,
a.btn.btn-label-brand:focus,
a.btn.btn-label-brand:hover,
a.btn.btn-label-brand.active,
button.btn.btn-label-brand:focus,
button.btn.btn-label-brand:hover,
button.btn.btn-label-brand.active,
input.btn.btn-label-brand:focus,
input.btn.btn-label-brand:hover,
input.btn.btn-label-brand.active {
  background-color: #49691f;
  color: #ffffff;
}

.btn.btn-label-brand {
  background-color: #49691f17;
  color: #49691f;
}

.kt-scrolltop {
  background: #fd9002;
}

.dashboard-logo {
  max-width: 30%;
  margin-top: 15%;
  margin-bottom: 15%;
}

.dashboard-main-view {
  text-align: center;
  margin-top: 15%;
}

.dashboard-main-title {
  font-size: 20px;
  font-weight: 500;
  color: #49691f;
  margin-top: 10px;
}

.kt-footer .kt-footer__copyright .kt-link:hover {
  color: #fd9002 !important;
}

.kt-login.kt-login--v1
  .kt-login__wrapper
  .kt-login__body
  .kt-login__form
  .kt-login__actions
  .kt-login__link-forgot:hover {
  color: #fd9002 !important;
}

.login-logo-view {
  text-align: center !important;
  display: block !important;
}

.login-logo-view img {
  width: 66%;
}

.login-logo-main-view {
  padding-top: 18% !important;
  border-right: 3px solid #49691f !important;
}

.login-main-left-title {
  font-size: 20px;
  font-weight: 500;
  color: #49691f;
  margin-top: 15px;
  text-align: center;
}

.login-form-view-padding {
  padding-top: 20%;
}

.main-color-1 {
  color: #36491d !important;
}

.main-color-2 {
  color: #fd9002 !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.login-form-input .form-control {
  height: calc(2em + 1.3rem + 2px);
}

.login-form-input .form-group label {
  font-size: 15px !important;
}

.gradient-button-view {
  font-size: 15px;
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  min-width: 137px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  border-radius: 10px;
}

.gradient-button-view:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.gradient-button {
  background-image: linear-gradient(
    to right,
    #fd9002 0%,
    #36491d 51%,
    #fd9002 100%
  );
}

.gradient-button:hover {
  background-position: right center;
}

.error-text-black {
  color: black;
  font-weight: 400;
}

.error-text-red {
  color: #fd397a;
  width: 100%;
  margin-top: -1.75rem;
  font-size: 80%;
  margin-bottom: 10px;
}

.error-text-red2 {
  color: #fd397a;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  margin-bottom: 10px;
}

.alert-text-orange {
  color: #fd9002;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  margin-bottom: 10px;
}

.error-icon {
  border: 2px solid;
  border-radius: 50%;
  padding: 4px 4px;
  font-size: 10px;
  color: red;
}

.success-icon {
  border: 2px solid;
  border-radius: 50%;
  padding: 4px 4px;
  font-size: 10px;
  color: #49691f;
}

.fixed-icon {
  border: 2px solid;
  border-radius: 50%;
  padding: 4px 4px;
  font-size: 25px !important;
  color: #49691f;
  /* margin-left: 5px;
    margin-right: 5px; */
}

.warning-icon {
  border: 2px solid;
  border-radius: 50%;
  padding: 4px 4px;
  font-size: 10px;
  color: #fd9002;
}

.login-hr-view-2 {
  border-color: #fd9002;
  border-width: 2px;
  width: 90px;
  margin: initial;
  margin-top: 15px;
  margin-left: 30%;
}

.login-hr-view-1 {
  border-color: #fd9002;
  border-width: 2px;
  width: 120px;
  margin: initial;
  margin-top: 15px;
  margin-left: 19%;
}

.inv-category-view {
  background: white;
  border-radius: 7px;
  padding: 10px;
  min-height: 100vh;
}

.inv-category-box {
  padding: 10px;
  border-left: 4px solid #fd9002;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 3px 2px 7px 1px #8888881a;
  background: #8080800d;
  margin-bottom: 15px;
}

.inv-category-box:hover {
  box-shadow: 3px 2px 7px 1px #88888852;
}

.inv-category-box-title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.inv-category-box-action-btn i,
.inv-category-box-action-btn svg {
  color: #808080b3;
  cursor: pointer;
  font-size: 14px;
}

.inv-category-box-action-btn i:hover,
.inv-category-box-action-btn svg:hover {
  color: #36491d !important;
  font-size: 15px;
}

.inv-category-box-action-btn svg {
  font-size: 23px;
}

.inv-category-box-action-btn svg:hover {
  font-size: 24px;
}

.inv-category-btn-view {
  margin-bottom: 15px;
  text-align: center;
  margin-top: 5px;
  border-bottom: 1px solid #80808036;
}

.gradient-button-font-12 {
  font-size: 12px;
}

.inv-class-view-title {
  font-size: 15px;
  font-weight: 400;
}

.inv-hr-view-2 {
  border-color: #fd9002;
  border-width: 2px;
  width: 40px;
  margin: initial;
  margin-top: 4px;
}

.inv-class-box {
  background: white;
  border-radius: 7px;
  padding: 10px;
  margin-top: 15px;
  height: 100px;
  box-shadow: 3px 2px 7px 1px #8888881a;
}

.inv-class-box-title {
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  min-height: 46px;
}

.search-form .form-group {
  margin-bottom: 0px;
}

.cost-value-supplier .form-group {
  margin-bottom: 5px;
}

.inv-class-img {
  height: auto;
  overflow: hidden;
  max-height: 79px;
}

.pointer-cursor {
  cursor: pointer;
}

.MuiPaper-root {
  background-color: #fff0 !important;
}

.MuiTypography-colorTextPrimary {
  color: #36491d !important;
}

a:hover {
  color: #36491d !important;
}

.MuiTypography-body1 {
  font-size: 15px !important;
}

.inv-cards-view {
  background: white;
  padding: 10px;
  margin-top: 20px;
  border-radius: 7px;
  padding-right: 20px;
  padding-left: 20px;
}

.table-header-row-main {
  color: #36491d;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.table-header-row {
  color: #36491d;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  /* padding-bottom:5px; */
  background:
        /* gradient can be an image */ linear-gradient(
      to left,
      #49691f 0%,
      hsl(34, 98%, 50%) 12%,
      #49691f 67%,
      #fd9002 100%
    )
    left bottom white no-repeat;
  background-size: 100% 2.5px;
}

.table-header-row-trasaction-page {
  font-size: 12px;
  font-weight: 600;
}

.table-header-row-color-1 {
  /* color: #36491d;
  font-size: 14px;
  font-weight: 500;
  padding: 10px; */
  /* padding-bottom:5px; */
  background:
        /* gradient can be an image */ linear-gradient(
      to left,
      #49691f 0%,
      #49691f 12%,
      #49691f 67%,
      #49691f 100%
    )
    left bottom white no-repeat;
  background-size: 100% 2.5px;
}

.table-header-row-color-2 {
  color: #36491d;
  font-size: 14px;
  font-weight: 500;
  padding: 10px !important;
  /* padding-bottom:5px; */
  background:
        /* gradient can be an image */ linear-gradient(
      to left,
      #fd9002 0%,
      #fd9002 12%,
      #fd9002 67%,
      #fd9002 100%
    )
    left bottom white no-repeat !important;
  background-size: 100% 2.5px !important;
}

.table-header-row-fixed-color {
  background:
        /* gradient can be an image */ linear-gradient(
      to left,
      #49691f 0%,
      #49691f 12%,
      #49691f 67%,
      #49691f 100%
    )
    left bottom white no-repeat;
  background-size: 100% 2.5px;
}

.table-body-row {
  padding: 10px;
  color: black;
  border-bottom: 1px solid #8080801a;
}

.table-body-row:hover {
  background-color: #36491d08;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.modal-left-0 {
  right: -73px !important;
  left: auto !important;
}

.overflow-y-cards {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-y-status {
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-item {
  text-align: start !important;
}

.action-icon-view .nav-link.dropdown-toggle:after,
.action-icon-view .btn.dropdown-toggle:after {
  display: none;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: rgb(184, 180, 180);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar-thumb {
  background: #49691f;
  border-radius: 10px;
}

.menu-dots-vertical {
  color: #fd9002;
  font-size: 22px !important;
}

.main-color-3 {
  color: #49691f !important;
}

.qr-code-img {
  text-align: center;
  margin-bottom: 20px;
}

.inv-selected-cat-box {
  border-bottom: 3px solid #fd900242;
}

.sidebar-modal-view {
  z-index: 130 !important;
}

.sidebar-modal-view .MuiBackdrop-root {
  background-color: #00000096 !important;
}

.sidebar-modal-view .MuiPaper-root {
  background-color: white !important;
}

/* .gray .MuiPaper-root {
    background-color: #e3e4e6 !important;
} */

.sidebar-modal-view .MuiDrawer-paperAnchorLeft {
  width: 40% !important;
}

.sidebar-modal-view .MuiDrawer-paperAnchorRight {
  width: 40% !important;
}

/* sidebar-modal-medium-view */

.sidebar-modal-medium-view {
  z-index: 130 !important;
}

.sidebar-modal-medium-view .MuiBackdrop-root {
  background-color: #00000096 !important;
}

.sidebar-modal-medium-view .MuiPaper-root {
  background-color: white !important;
}

.sidebar-modal-medium-view .MuiDrawer-paperAnchorLeft {
  width: 60% !important;
}

.sidebar-modal-medium-view .MuiDrawer-paperAnchorRight {
  width: 60% !important;
}

/* sidebar-modal-medium-view */

.sidebar-modal-large-view {
  z-index: 130 !important;
}

.sidebar-modal-large-view .MuiBackdrop-root {
  background-color: #00000096 !important;
}

.sidebar-modal-large-view .MuiPaper-root {
  background-color: white !important;
}

.sidebar-modal-large-view .MuiDrawer-paperAnchorLeft {
  width: 80% !important;
}

.sidebar-modal-large-view .MuiDrawer-paperAnchorRight {
  width: 80% !important;
}

.sidebar-modal-x-large-view {
  z-index: 130 !important;
}

.sidebar-modal-x-large-view .MuiBackdrop-root {
  background-color: #00000096 !important;
}

.sidebar-modal-x-large-view .MuiPaper-root {
  background-color: white !important;
}

.sidebar-modal-x-large-view .MuiDrawer-paperAnchorLeft {
  width: 90% !important;
}

.sidebar-modal-x-large-view .MuiDrawer-paperAnchorRight {
  width: 90% !important;
}

.view-modal-style {
  width: 100% !important;
}

.sidebar-header-view {
  padding: 17px 19px;
  box-shadow: 0px 3px 9px 0px #49691f82;
  background: linear-gradient(
      to left,
      #49691f 0%,
      #fd9002 12%,
      #49691f 67%,
      #fd9002 100%
    )
    left bottom white no-repeat;
  background-size: 100% 2.5px;
}

/* .sidebar-header-view .gray {
    background-color: #e3e4e6 !important;
} */

.view-chat-box {
  background-color: #ffb8221c;
  padding: 10px;
  margin: 10px;
  border-radius: 7px;
  overflow-wrap: break-word;
}

.scroll-buttom .MuiPaper-root {
  flex-direction: column-reverse;
}

.view-date {
  color: #808080;
}

.view-time-chat {
  direction: initial !important;
  text-align: right;
  font-size: 12px;
  padding-top: 10px;
  color: #808080;
}

.view-hr-chat {
  margin-top: 0.8rem;
}

.img-view-chat {
  border-radius: 7px;
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
}

.view-chat-box-sender {
  background-color: #80b7372b;
  padding: 10px;
  margin: 10px;
  border-radius: 7px;
  overflow-wrap: break-word;
}

.fixed-on-scroll {
  position: fixed;
  top: 0;
  width: 39.8%;
  z-index: 10;
}

.scroll {
  height: 100vh;
  overflow: scroll;
}

.view-scroll-div {
  background-color: #ffffffed;
  /* background-color: #ffffff85; */
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.fixed-on-scroll-button {
  position: fixed;
  top: 10;
  width: 39.8%;
  z-index: 10;
}

.color-button-scroll {
  background: #ffffff85 !important;
}

.sender {
  justify-content: left;
  padding-right: 10px;
}

.reciver {
  justify-content: right;
  padding-left: 10px;
}

.sidebar-header-title {
  font-size: 15px;
  font-weight: 500;
}

.border-raduis-summary-box {
  border-radius: 7px;
}

.modal-close-icon {
  color: #49691f;
  font-size: 24px !important;
  cursor: pointer;
}

.sidebar-body-view {
  margin-top: 16px;
  padding: 15px;
}

.finDistReport-square-10 {
  height: 15px;
  width: 15px;
  background-color: #110589;
}

.finDistReport-square-11 {
  height: 15px;
  width: 15px;
  background-color: #866cc3;
}

.page-action-btn {
  border: 2px solid gray;
  border-radius: 7px;
  color: #585353;
  background: white;
  font-weight: 500;
}

.page-action-btn:hover {
  box-shadow: 0px 3px 9px 0px gray;
  color: gray;
}
.modal-body-3,
.modal-box-b {
  padding: 10px;
}
.form-save-btn {
  border: 2px solid #49691f;
  border-radius: 7px;
  color: #49691f;
  background: white;
  font-weight: 500;
}
.refresh-container-2 {
  width: 215px;
}
.form-save-btn-2 {
  padding: 0px !important;
  min-height: 35px;
  min-width: -webkit-fill-available !important;
}
.form-save-btn-9 {
  padding: 5.5px;
  width: 100%;
}
.form-save-btn:hover {
  box-shadow: 0px 3px 9px 0px #49691f82;
  color: #49691f;
}

.form-cancel-btn:hover {
  box-shadow: 0px 3px 9px 0px #fd90024f;
  color: #fd9002;
}

.form-cancel-btn {
  border: 2px solid #fd9002;
  border-radius: 7px;
  color: #fd9002;
  background: white;
  font-weight: 500;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #49691f !important;
  border-color: #49691f !important;
  background-color: #49691f !important;
}

.custom-control-label::after {
  top: 0.25rem;
  /* left: -1.53rem; */
}
.close-status-message {
  font-weight: 500;
  color: black;
  margin: 30px;
}
.delete-icon-1 {
  color: gray;
}
.delete-icon-1:hover {
  cursor: pointer;
  color: black;
}
.delete-icon-1:active {
  cursor: pointer;
  color: gray;
  scale: 0.9;
}
.fileInput-span {
  font-size: 0.875rem;
  border: 1px solid #ced4da;
  display: block;
  padding: 0.45rem 0.9rem;
  font-weight: 400;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.2rem;
  height: 36px;
}
.fileInput-span:hover {
  cursor: pointer;
}
.fileInput-width {
  width: 100%;
  border-radius: 5px !important;
}

.fileInput-opacity {
  opacity: 0;
  position: absolute;
  right: 0px;
  font-size: 10px;
  cursor: pointer;
  outline: none;
}

.modal-close-icon:hover {
  color: #fd9002;
}

.progress-bar {
  background-color: #49691f !important;
}

.overflow-text-length {
  white-space: nowrap !important;
  overflow: hidden !important;
  -o-text-overflow: ellipsis !important;
  text-overflow: ellipsis !important;
}

.white-space_pre-wrap {
  white-space: pre-wrap !important;
}

.hover-scroll-table-row {
  background: #f1f5f7 !important;
}

.no-data-img {
  width: 30%;
}

.no-data-text {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  color: gray;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #49691f !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #49691f !important;
}

.rep-header-logo {
  max-width: 15% !important;
}

.resp-menu-icon {
  border: 0px;
  background: white;
  color: #49691f;
  font-size: 18px;
}

.inv-category-img {
  max-height: 150px;
  border: 1px solid #8080803d;
  border-radius: 7px;
  padding: 10px;
}

.inv-category-img-title {
  display: block;
  padding-top: 10px;
  color: #49691f !important;
  font-weight: 500;
  width: 250px;
  margin: auto;
}

.inv-cat-list-height {
  max-height: 90vh;
  overflow: auto;
  padding-right: 10px;
}

.alert-box-view {
  max-height: 70vh;
  text-align: center;
}
.alert-box-view h5,
.break-words {
  white-space: pre-line;
  word-break: break-word;
  overflow-wrap: break-word;
  height: auto !important;
}
.no-margin-from-control .MuiFormControlLabel-root {
  margin: 0px !important;
}
.alert-delete-icon {
  color: #f7b84b;
  border-color: #f7b84b;
  border: 2px solid;
  border-radius: 50%;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 900;
  font-size: 20px;
}

.alert-Succsess-icon {
  color: #f7b84b;
  border-color: #f7b84b;
  border: 2px solid;
  border-radius: 50%;
  padding-right: 17px;
  padding-left: 17px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 900;
  font-size: 20px;
}

.react-confirm-alert {
  background: white;
  padding: 20px;
  border-radius: 20px;
  width: 30%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.p-t-30px {
  padding-top: 30px;
}

.color-background-gray {
  background-color: #f7f8fa;
}

.view-card-class-form {
  padding: 10px;
  border-radius: 10px;
  background-color: #f7f8fa;
}

.react-confirm-alert-overlay {
  background: rgba(39, 36, 36, 0.37) !important;
  z-index: 999999;
}

.inv-cat-main-title {
  font-size: 16px;
  font-weight: 500;
}

.inv-card-empty-image {
  height: 71px;
  width: 86px;
}

.inv-card-class-img-note {
  border-top: 1px solid #8080801f;
  padding-top: 7px;
}

.filter-form .form-control,
.filter-form .fs-16 {
  font-size: 16px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.f-15 {
  font-size: 15px;
}

.fs-12 {
  font-size: 12px;
}

.fixed-price-form {
  border-bottom: 1px solid #80808017;
  text-align: center;
}

.fixed-price-form .form-group {
  margin-bottom: 5px;
  margin-top: 5px;
}

.fixed-price-currency-field {
  background: #8080801f;
  border-radius: 4px;
}

.form-without-error-msg .invalid-feedback {
  display: none;
}

.msg-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fd397a;
}

.label-error {
  color: #fd397a !important;
  font-weight: 400;
  font-size: 1rem;
}

.class-action-supplier-hr {
  width: 90%;
}

.left-menu-padding {
  padding: 9px 10px !important;
}

.pagenation-view {
  float: right;
}

.file-icon-view {
  float: left;
}

.without-border {
  border-width: 0 !important;
}

.without-border-bottom {
  border-bottom-width: 0px !important;
}

@keyframes DropDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

.collaps-view {
  background-color: #fff;
  padding: 10px;
  border-color: #ebedf2;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  margin-top: 1px;
  margin-left: 1px;
  margin-bottom: 2px;
  margin-right: 1px;
  font-size: 13px;
  color: #36491d;
}

.count-card-title {
  color: #36491d;
  font-size: 13px;
  font-weight: 600;
  padding-right: 13px;
  padding-left: 13px;
}

.collaps-view:hover {
  box-shadow: 0px 0px 10px 0px #d0cece6e;
  color: gray;
}

.page-link {
  color: #757272 !important;
  font-weight: 500;
}

.page-item.active .page-link {
  background-color: white !important;
  border: 2px solid;
  border-color: #757272 !important;
  border-radius: 7px;
}

.page-link:focus {
  box-shadow: 0 0 0 0.15rem rgb(193 187 187 / 15%) !important;
}

.page-link:hover {
  font-weight: 500;
  background-color: white !important;
  border: 2px solid !important;
  border-color: #75727200 !important;
  border-radius: 7px;
}

.pagination-rounded .page-link {
  background-color: white !important;
  border: 2px solid !important;
  border-color: #75727200 !important;
  border-radius: 7px;
}

.page-filter-view {
  background: white;
  padding: 15px;
  border-radius: 7px;
}

.filter-form .form-group {
  margin-bottom: 3px !important;
}

.filter-form .form-control,
.filter-form label {
  font-size: 11px !important;
}

.filter-form label {
  margin-bottom: 2px !important;
  margin-top: 7px !important;
}

.reset-filter-btn {
  cursor: pointer;
  font-size: 18px !important;
  color: #f7b84b;
}

.request-filter-btn {
  cursor: pointer;
  font-size: 20px !important;
  color: black;
}

.refresh-action-btn {
  padding: 0px !important;
  background: white !important;
  border: 1px solid #f7b84b !important;
  min-width: -webkit-fill-available !important;
  line-height: 1.7 !important;
}

.accept-action-btn {
  padding: 0px !important;
  background: white !important;
  border: 1px solid #f7b84b !important;
  line-height: 1.7 !important;
}

.member-searchable-list {
  font-size: 11px;
  color: #495057;
  font-weight: 400;
}

.member-searchable-list .picky__input {
  width: 100%;
  background-color: white;
  border: 0;
  border-width: 1px;
  border-color: #e2e5ec !important;
  border-style: solid;
  border-radius: 4px !important;
  padding: 5px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  height: 38px;
}

.picky__input {
  padding: 0.65rem 1rem !important;
}

.left-menu-balance-view {
  padding-bottom: 41px !important;
  border-top: 2px solid #fd900230;
  padding-top: 20px;
  font-size: 15px;
}

.info-icon {
  font-size: 16px;
}

.padding-5 {
  padding: 5px;
}

.width-100 {
  width: 100%;
}

.view-purchase-main-fields {
  background: #49691f0d;
  padding: 10px;
  border-radius: 7px;
}

.view-purchase-main-fields-row {
  border-left: 4px solid #f7b84b;
  border-radius: 3px;
}

.view-purchase-cards-box {
  border: 1px solid #80808033;
  padding: 10px;
  border-radius: 7px;
  height: 263px;
  box-shadow: 2px 2px 4px 0px #8888889e;
  margin-bottom: 13px;
}

.view-purchase-order-num {
  border-bottom: 1px solid #80808045;
  text-align: center;
  padding-bottom: 7px;
}

.view-purchase-cards-box-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
}

.view-purchase-cards-box-row {
  border-left: 2px solid #49691f;
  margin-left: -1px;
}

.view-purchase-cards-box-col {
  height: 90vh;
  overflow-y: scroll;
}

.view-purchase-cards-box:hover {
  background: #8080800f;
  cursor: pointer;
  box-shadow: 3px 3px 5px 1px #8888889e;
}

.transparent-label label {
  color: #ffffff00;
}

.add-purchase-order-form-border {
  border-right: 1px solid #8080802e;
}

.form-control[readonly] {
  background-color: #d9d7d73d !important;
  cursor: not-allowed !important;
}

.height-edit-purchaseOrders {
  height: 290px !important;
}

.purchaseOrders-class-form-box {
  border: 1px solid #80808052;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 4px 0px #88888873;
  height: 220px;
}

.purchaseOrders-class-form-box .form-group {
  margin-bottom: 10px !important;
}

.purchaseOrders-class-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
}

.view-supplier-transaction-modal .MuiPaper-root {
  background-color: #f7f8fa !important;
}

.sidebar-body-view-supplier-transaction {
  padding-left: 35px;
  padding-right: 35px !important;
}

.no-hover {
  pointer-events: none;
  color: #212529;
}

.pos-main-profile {
  background: white;
  padding: 15px;
  border-radius: 7px;
}

.pos-main-profile-main-btn {
  padding: 4px !important;
  border: 2px solid !important;
  border-image-slice: 1 !important;
  border-width: 2px !important;
  border-radius: 15px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
  color: #334f0f !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  box-shadow: 2px 2px 9px 0px #80808059 !important;
}

.pos-main-profile-main-btn:hover {
  box-shadow: 6px 6px 9px 0px #80808059;
}

.pos-main-profile-main-btn-view {
  border-top: 1px solid #8080801c;
  padding-top: 24px;
}

.pos-main-profile-main-btn-gradient {
  border-image-source: linear-gradient(to left, #f7b84b, #49691f) !important;
  border-image-source: 1 !important;
}

.black-font {
  color: black;
}

.bold-font {
  font-weight: bold;
}

.pos-main-profile-section-box {
  background: white;
  padding: 25px;
  border-radius: 7px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #8080803b;
  box-shadow: 5px 5px 4px 0px #8080807a;
  margin-bottom: 20px;
}

.pos-main-profile-section-box-icon {
  font-size: 70px !important;
  color: #8080809c;
}

.mt-32-px {
  margin-top: 32px !important;
}

.pos-main-profile-section-box:hover .pos-main-profile-section-box-icon {
  color: #49691f;
}

.email-icon-hover:hover {
  box-shadow: 0px 2px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 4px 0px rgb(0 0 0 / 14%), 0px 3px 10px 0px rgb(0 0 0 / 12%) !important;
}

.pos-financial-report-refresh-btn {
  margin-top: 25px !important;
}

.custom-form-label-checkbox .custom-control-label {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.custom-form-label-checkbox .custom-control-label {
  padding-top: 3px !important;
}

.pos-financail-report-summary-row {
  border-bottom: 1px solid #8080801f;
  padding-bottom: 10px;
  padding-top: 10px;
}

.pos-financail-report-summary-view {
  /* border: 2px solid #49691f;
  border-radius: 7px; */
  border-top: 2px solid #49691f;
  border-bottom: 2px solid #49691f;
}

table {
  width: 100% !important;
}

table,
th,
td {
  border: 1.5px solid #80808069;
  border-collapse: collapse;
  padding: 7px;
}

td {
  color: black;
}

th {
  text-align: center;
  color: #49691f;
  font-weight: 500 !important;
}

.main-table-header {
  border-bottom: 2px solid #49691f;
}

.notification-multi-lang-hr {
  border-top: 1.4px dashed #49691f;
}

.notification-multi-lang-view {
  background: #8080800d;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 2px 2px 6px 0px #80808030;
}

.dropdown-menu.dropdown-menu-xl {
  width: 220px !important;
}

.view-purchase-main-fields-row-color-2 {
  border-left: 4px solid #49691f;
  border-radius: 3px;
}

.table-final-result-row {
  background: #80808017;
  font-weight: 500 !important;
  font-size: 14px;
}

.report-table-padding table,
.report-table-padding th,
.report-table-padding td {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.input-label {
  font-size: 1rem;
  font-weight: 400;
}

.refresh-btn-margin {
  margin-top: 25px !important;
}

.status-1 {
  color: #49691f;
  font-weight: 600;
}

.status-2 {
  color: #8a3324;
  font-weight: 600;
}

.status-0 {
  color: #f5a845eb;
  font-weight: 600;
}

.filter-hr {
  background-image: linear-gradient(75deg, #49691f, #f7b84b);
  border: 0;
  height: 1px;
}

.filter-send-email-btn {
  padding: 6.5px 4px !important;
}

.filter-empty-email {
  border-color: #fd397a;
}

.alert-border-input {
  border-color: #fd9002;
}

.financail-operation-report-summary-view {
  padding: 1rem;
  /* color: white; */
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #49691f, #f7b84b) 2 100%;
  box-shadow: 5px 5px 10px 2px #dedbdb;
  margin-bottom: 17px;
}

.financail-operation-report-summary-box-view {
  box-shadow: 3px 3px 6px 0px #8080806b;
  border-radius: 7px;
  border: 1px solid #80808030;
}

.financail-operation-report-summary-lable:hover > div {
  font-weight: 900 !important;
  cursor: pointer;
}

.pos-financail-report-summary-last-row {
  border-top: 2px solid #49691f;
}

.account-statement-report-summary-box-view {
  box-shadow: 2px 2px 4px 0px #8080806b;
}

.hr-report-margin {
  margin-right: 10% !important;
  margin-left: 10% !important;
}

.hr-dashboard-view {
  width: 50%;
}

.report-chart-box {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 3px 4px 10px #80808061;
}

.report-total-box {
  background: white;
  border-radius: 5px;
  position: relative;
  box-shadow: 5px 5px 8px #80808061;
}

.report-total-box-color-view {
  border-radius: 5px;
  height: 70px;
  background-image: linear-gradient(180deg, #36491d, #f7b84b);
}

.report-total-box-color-2-view {
  border-radius: 5px;
  height: 70px;
  background-image: linear-gradient(180deg, #f7b84b, #36491d);
}

.report-total-box-white-view {
  height: 70px;
}

.report-total-box-data-view {
  position: absolute;
  top: 25px;
  background: white;
  width: 80%;
  border-radius: 5px;
  text-align: center;
  right: 10%;
  left: 10%;
  height: 90px;
  box-shadow: 4px 4px 8px #80808059;
  padding: 20px;
}

.report-total-box-data-view:hover {
  cursor: pointer;
  box-shadow: 4px 4px 8px #807e7ec4;
}

.f-16 {
  font-size: 16px !important;
}

.f-20 {
  font-size: 20px !important;
}
.f-s-1r {
  font-size: 1rem !important;
}
.f-30 {
  font-size: 30px !important;
}
.payment-req-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  width: 100%;
  border-radius: 8px;
  background: #f2f3f8;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid #ffffff00;
  margin: 10px;
}
.selected-payment-box {
  border: 2px solid #36491d;
}
.scrollable-box {
  overflow: auto;
  max-height: 300px;
}

.buttom-border-tool-tip {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  margin-left: 0.1px;
  margin-right: 0.1px;
}

.report-total-main-view {
  margin-top: 6.5%;
}

.report-total-main-view-admin {
  margin-top: 1.5%;
}

.report-count-main-view {
  margin-top: 42%;
}

.f-17 {
  font-size: 17px;
}

.chart-2-max-view:hover,
.chart-2-min-view:hover {
  box-shadow: 4px 4px 8px #807e7ec4;
}

.add-order-active-step {
  background: #36491d;
  color: white;
  border-radius: 50%;
  text-align: center;
  padding: 11px;
  width: 66px;
  cursor: pointer;
}

.add-order-not-active-step {
  background: gray;
  color: white;
  border-radius: 50%;
  text-align: center;
  padding: 11px;
  width: 60px;
  cursor: pointer;
}

.add-order-step-active-icon {
  font-size: 45px !important;
}

.add-order-step-not-active-icon {
  font-size: 35px !important;
}

.add-order-active-step-title {
  text-align: center;
  padding-top: 7px;
  color: #36491d;
  font-weight: 500;
}

.add-order-active-arrow-icon {
  margin-top: -26px;
  color: #36491d;
}

.add-order-not-active-arrow-icon {
  margin-top: -26px;
  color: gray;
}

.add-order-main-steps-view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.add-order-step-form-view {
  background: white;
  padding: 10px;
  margin-top: 25px;
  border-radius: 8px;
  box-shadow: 2px 5px 11px #80808052;
  min-height: 477px;
  margin-bottom: 15px;
}

.add-order-next-btn-view {
  position: absolute;
  bottom: 6%;
  right: 4%;
}

.add-order-cat-img {
  width: 150px;
  /* width: 180px; */
  border-radius: 7px;
  /* box-shadow: 6px 5px 9px #80808075; */
}

.add-order-cat-view {
  box-shadow: 6px 5px 9px #80808075;
  padding-top: 15px;
  padding-bottom: 11px;
  background: #8080800a;
  /* height: 248px; */
  height: 217px;
  margin: 15px 3px;
  cursor: pointer;
}

.add-order-cat-view:hover {
  background: #80808017;
}

.add-order-cat-name {
  /* max-width: 154px; */
  max-width: 137px;
  white-space: nowrap !important;
  overflow: hidden !important;
  -o-text-overflow: ellipsis !important;
  text-overflow: ellipsis !important;
}

.add-order-cat-img-view {
  /* height: 180px !important; */
  height: 146px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-order-cat-selected-view {
  border: 2px solid #f7b84b;
  border-radius: 4px;
}

.add-order-steps-main-view {
  margin-bottom: 10%;
}

.not-allowed-cursor {
  cursor: not-allowed !important;
}

.add-order-class-box-form-view {
  text-align: center;
  border: 1px solid #80808017;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 3px 4px 14px #80808052;
  color: black;
  /* height: 183px; */
  height: 205px;
  font-weight: 500;
}

.alert-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #4c7318;
}

.min-width {
  min-width: 70px !important;
}

.add-order-class-box-form-view .form-group {
  margin-bottom: 0px !important;
}

.inter-Calls .form-group {
  margin-bottom: 0px !important;
}

.cat-search-div .form-group {
  margin-bottom: 15px !important;
}

.search-input-cat {
  height: 25px !important;
}

.add-order-class-box-form-view input {
  text-align: center !important;
}

.add-order-class-box-form-title {
  border-bottom: 1px solid #80808017;
  padding-bottom: 5px;
}

.add-order-alert-btn {
  min-width: auto !important;
  padding-right: 19px !important;
}

.add-order-password-icon-view {
  width: 10% !important;
  margin-top: 7%;
}

.add-order-password-input-view .form-group {
  width: 90% !important;
}

.add-order-password-input-view {
  width: 100%;
}

.buyCard-Category-CardWidth {
  width: 20%;
}

.orderClassWidth {
  width: 12%;
}

.restore-icon {
  border: 2px solid;
  border-radius: 50%;
  padding: 1.5px 1.5px;
  font-size: 21px !important;
}

.page-filter-view-cards-transfer {
  background: white;
  padding: 15px;
  /* padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px; */
  border-radius: 7px;
  margin-left: 45px;
  margin-right: 45px;
  justify-content: center;
}

.card-hr-view {
  border-color: #49691f;
  border-width: 2px;
  width: 30px;
  margin: initial;
  margin-top: 4px;
}

.card-haeder {
  font-weight: 600 !important;
  font-size: 13px !important;
  color: #49691f;
}

/* .dev-card-class-order-left {
    padding-right: 15px !important;
}
.dev-card-class-order-right {
    padding-left: 15px !important;
    border-left: solid #a2a5b940;
    border-width: 1.9px;
} */
.CircularProgressColor {
  width: 16px !important;
  height: 16px !important;
  color: #f7b84b !important;
}

.CircularProgressColor-dark-gray {
  width: 16px !important;
  height: 16px !important;
  color: #9e9180 !important;
}

.CircularProgressColor-white {
  width: 16px !important;
  height: 16px !important;
  color: #f9f7f4 !important;
}

.cat-search-div-filter .form-group {
  margin-bottom: 9px !important;
}

.CircularProgressColor-white:hover {
  color: #9e9180 !important;
}

.SaveCircularProgressColor {
  width: 15px !important;
  height: 15px !important;
  color: #49691f !important;
}

.not-active-sort {
  color: #8c9ba9 !important;
}

.error-icon-small {
  border: 1px solid;
  border-radius: 50%;
  /* padding: 2px; */
  padding: 2px 2px;
  font-size: 7px;
  color: red;
}

.filter-inv .picky__input {
  height: 28px !important;
  padding: 1px !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.filter-inv .picky__input::after {
  top: 12px;
}

.gradient-button-view-order {
  font-size: 15px;
  padding: 4px 20px;
  text-align: center;
  transition: 0.5s;
  background-size: 156% auto;
  color: #fff;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  min-width: 70px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  border-radius: 10px;
}

.f-100 {
  font-size: 100px !important;
}

.tools-box {
  padding: 60px !important;
}

.requestStatusStyle {
  text-align: left;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* ///tooltip/// ------------start*/

.rc-tooltip-inner {
  font-size: 14px;
  padding: 8px 10px;
  color: #36491d;
  /* text-align: left; */
  text-decoration: none;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 3px rgb(0 0 0 / 36%);
  min-height: 34px;
  min-width: 200px;
  font-weight: 800;
  max-width: 400px;
}

.rc-tooltip-inner-card-class {
  font-size: 13px !important;
  min-height: 50px !important;
  min-width: 380px !important;
}
.img-dsp {
  width: 200px;
}
.c-card-2 {
  border-radius: 5px;
  border: solid 2px #0782c1;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 60% !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-right-color: #ffffff;
}

.tooltipRequestStyle {
  font-size: 12px;
}

.tooltipRequestStyle1 {
  font-size: 13px;
}

.requestDataTitleStyle {
  font-size: 14px;
}

.mt-37px {
  margin-top: 37px;
}

/* ///RequestOrder----page-start */

.requestfilter-form .form-group {
  margin-bottom: 8px !important;
}
.text-bold {
  font-weight: 700 !important;
}
.posPicky .picky__input--disabled,
.picky__input--disabled + .picky__dropdown .option {
  color: rgb(95, 94, 94);
  background-color: #f7f8fa !important;
}

.custom-form-phone-view label {
  font-size: 1rem;
  font-weight: 600;
  color: #6c757d;
}

.custom-form-phone-view .PhoneInputInput {
  border-radius: 3px;
  border: 1px #80808061 solid;
}

.close-modal-btn {
  position: absolute;
  top: -0.86rem;
  background-color: #49691f;
  color: #fff;
  padding: 1px 8px;
  border: 1px solid #fff;
  border-radius: 25px;
  right: -0.5rem;
  font-size: 15px;
  cursor: pointer;
  z-index: 1000;
}
/* .form-save-btn {
  wid
} */
.requestdataModal .modal-body {
  min-height: 215px !important;
  padding: 12px 12px 0px !important;
}

.copyTextStle .MuiAlert-root {
  padding: 0px !important;
}

.copyTextStle .MuiAlert-message {
  font-size: 12px;
  padding: 3px 0;
}

.editPhoneForm .form-group {
  margin-bottom: 0px !important;
}

.copyTextStle .MuiAlert-icon {
  margin-right: unset !important;
  padding: 0px !important;
}

.editPhoneLabelStyle {
  color: #808080c4;
  font-size: 10px;
  margin-bottom: 15px;
}

.ReceiptColor {
  color: #74788d !important;
}

.ReceiptColor a:hover {
  color: #74788d !important;
}

.task-action-btn-loading {
  opacity: 0.5;
}

.task-action-btn-loading:hover {
  box-shadow: unset;
  cursor: no-drop;
}

.applay-to-all-btn {
  border-bottom: 1px dashed;
  width: fit-content;
  cursor: pointer;
}

.applay-to-all-btn:hover {
  color: #49691f;
}

.inv-cat-box-padding {
  padding: 1rem 1rem 1px 1rem;
  background: #f9f9f9;
  /* margin-bottom: 1rem; */
}

.inv-cat-box-padding .purchase-info .inv-category-box {
  border-left: 3px solid #fd9002 !important;
  border-right: 0;
}

.inv-cat-box-padding .inv-selected-cat-box {
  border-bottom: 0;
}

.inv-cat-box-padding .inv-category-box {
  box-shadow: none;
  background: none;
  margin-bottom: 15px;
}

.purchase-info .inv-category-box {
  border-right: 0;
}

.inv-cat-box-padding .inv-category-box-title {
  cursor: auto;
}

.mb-form .form-group {
  margin-bottom: 3px;
}

.addOrder .add-order-cat-img {
  width: 100px;
}

.addOrder .add-order-cat-view {
  height: 200px;
  margin: 15px 15px;
}

.addOrder .add-order-cat-img-view {
  height: 130px !important;
}

.break {
  word-break: break-all;
}

.text-start {
  text-align: start !important;
}

/* ///RequestOrder----page-End */
.task-action-file-details-delete-icon {
  font-size: 12px;
  cursor: pointer;
  margin-top: 10px;
}

.task-action-file-details-delete-icon span:hover {
  color: #fd9002;
}

.actionDropdownListPadding-1 {
  padding: 0 1.3rem;
}

.pt-8 {
  padding-top: 8px !important;
}

.font-title-accouny-bal {
  font-size: 16px !important;
  border-bottom: 1px solid #8080801c;
  height: 40px;
}

.ml-4 {
  margin-left: 4ch !important;
}

.with-fit {
  width: fit-content !important;
}

.ml-6 {
  margin-left: 5rem !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.account-bal-section-box {
  background: white;
  padding: 20px;
  border-radius: 7px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #8080803b;
  box-shadow: 5px 5px 4px 0px #8080807a;
  height: auto;
  width: -moz-fit-content;
  margin: 10px;
  float: right;
}

.action-account-bal {
  height: 30px !important;
  color: black;
}

.border-account {
  border-bottom: 1px solid #8080801c;
}

.mr-ml-50 {
  margin-right: 50px;
  margin-left: 50px;
}

.ml-2 {
  margin-left: 20px;
}

.wd-100 {
  width: 100px;
}

.pl-10 {
  padding-left: 10px;
}

.font-w-600 {
  font-weight: 600;
}

.hover:hover {
  color: #f2710f !important;
}

.hover-green:hover {
  color: #607f37 !important;
}

.hover-dark-green:hover {
  color: #36491d !important;
}

.hover-gray:hover {
  color: #9da191 !important;
}

.tp-icon-del {
  padding-top: 5px;
}

.pt-25px {
  padding-top: 25px !important;
}

.link-color-sample {
  color: #5578eb !important;
}

.link-color-sample:hover {
  color: #49691f !important;
}

.box-form-fileds .form-group {
  margin-bottom: 9px !important;
}

.mr-b-avf .form-group {
  margin-bottom: 0px !important;
}

.width-avf {
  width: 70%;
}

.width-avf-tax {
  width: 90%;
  height: 90%;
}

.fontSize {
  font-size: 10px !important;
}

.w-61 {
  width: 18.5%;
}

.error-alert-icon {
  color: #fd397a !important;
  font-size: 40px !important;
  cursor: pointer;
}

.success-alert-icon {
  color: #49691f !important;
  font-size: 40px !important;
  cursor: pointer;
}

.success-btn {
  border-color: #49691f !important;
}

.text-success {
  color: #49691f !important;
}

.reject-color {
  color: #8a3324 !important;
}

.delete-color {
  color: #970000 !important;
}

.gray:hover {
  cursor: pointer;
  color: #979696 !important;
}

.pos-recharge-mng-color {
  color: #110589 !important;
}

.pos-correct-mng-color {
  color: #866cc3 !important;
}

.addCredit-color {
  color: #96c8a2 !important;
}

.wthdrawal-color {
  color: #e67451 !important;
}

.profit-color {
  color: #064e40 !important;
}

.status-8-color {
  color: #54072c !important;
}

.status-9-color {
  color: #626eaf !important;
}

.status-square-view {
  width: inherit;
  max-width: 50%;
  text-align: left;
  border: 1px solid #b7b7b7;
  /* width: fits-content; */
  /* width: 32%; */
  padding: 10px;
  /* margin: 10px; */
  border-radius: 5px;
  background: white;
  font-size: 12px;
  margin-top: 30px;
}

.status-square-view-emp {
  width: inherit;
  /* max-width: 50%; */
  text-align: left;
  border: 1px solid #b7b7b7;
  /* width: fits-content; */
  /* width: 32%; */
  padding: 10px;
  /* margin: 10px; */
  border-radius: 5px;
  background: white;
  font-size: 12px;
  margin-top: 10px;
}

.task-square-view {
  text-align: left;
  border: 1px solid #b7b7b7;
  width: fit-content;
  padding: 10px;
  /* margin: 10px; */
  border-radius: 5px;
  background: white;
  font-size: 12px;
  margin-top: 30px;
}

.flex-distplay {
  display: flex;
}

.task-square-div {
  display: flex;
  margin-bottom: 6px;
}

.task-square-1 {
  height: 15px;
  width: 15px;
  background-color: #848482;
}

.task-square-2 {
  height: 15px;
  width: 15px;
  background-color: #f5a845eb;
}

.task-square-3 {
  height: 15px;
  width: 15px;
  background-color: #607f37;
}

.task-square-4 {
  height: 15px;
  width: 15px;
  background-color: #8a3324;
}

.status-square-1 {
  height: 15px;
  width: 15px;
  background-color: #848482;
}

.status-square-2 {
  height: 15px;
  width: 15px;
  background-color: #f5a845eb;
}

.status-square-3 {
  height: 15px;
  width: 15px;
  background-color: #607f37;
}

.status-square-4 {
  height: 15px;
  width: 15px;
  background-color: #8a3324;
}

.status-square-5 {
  height: 15px;
  width: 15px;
  background-color: #96c8a2;
}

.optgroup-background {
  /* background-color: #96C8A2; */
  background-color: #f5f7f2;
}

.option-background {
  background-color: white;
}

.status-square-6 {
  height: 15px;
  width: 15px;
  background-color: #e67451;
}

.status-square-7 {
  height: 15px;
  width: 15px;
  background-color: #064e40;
}

.status-square-8 {
  height: 15px;
  width: 15px;
  background-color: #54072c;
}

.status-square-9 {
  height: 15px;
  width: 15px;
  background-color: #626eaf;
}
.status-square-10 {
  height: 15px;
  width: 15px;
  background-color: #c815c8;
}
.status-10-color {
  color: #c815c8;
}
.finDistReport-square-1 {
  height: 15px;
  width: 15px;
  background-color: #96c8a2;
}

.finDistReport-square-2 {
  height: 15px;
  width: 15px;
  background-color: #e67451;
}

.finDistReport-square-3 {
  height: 15px;
  width: 15px;
  background-color: #064e40;
}

.js-c {
  justify-content: flex-end;
}

.finDistReport-square-view {
  margin-top: 13px;
  text-align: left;
  border: 1px solid #b7b7b7;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  background: white;
  font-size: 12px;
  /* height: 205px; */
}

.finDistReport-square-view-pos {
  margin-top: 10px;
  text-align: left;
  border: 1px solid #b7b7b7;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  background: white;
  font-size: 12px;
  /* height: 90px; */
  margin-left: 10px;
}

.balRechargeDist-square-view {
  text-align: left;
  border: 1px solid #b7b7b7;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  background: white;
  font-size: 12px;
}

.mt-130 {
  margin-top: 130px !important;
}

.SaveCircularProgressColor-org {
  width: 15px !important;
  height: 15px !important;
  color: #fdfffb !important;
  margin-left: "15px";
}

.p-l-r-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.alert-msg-1 {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 86%;
  color: #495057;
}

.display-contents {
  display: contents;
}

.inv-menu {
  padding: 0 !important;
}

.box-form-fileds {
  background-color: #f7f8fa;
  padding: 10px;
  border-radius: 12px;
  padding-left: 15px;
  padding-right: 15px;
}

.hr-title {
  margin-top: 7px;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.disablied-supplier .picky__input::after {
  display: none !important;
}

.disabled-supplier {
  background-color: #ffffff !important;
}

.m-b-7 {
  margin-bottom: 7px;
}

.m-l-r-10px {
  margin-left: 10px;
  margin-right: 10px;
}

/* ////@media-Section-Start////// */
/* .ckeditorbackground .cke_top {
    background: transparent!important;
}

.ckeditorbackground .cke_bottom {
    display: none!important;
} 

.ckeditorbackground a.cke_button {  
    padding: 4px 5px !important
}

.ckeditorbackground a.cke_button_on { 
    height: 16px !important;
}

.ckeditorbackground a.cke_button_off:hover, .ckeditorbackground a.cke_button_off:focus, .ckeditorbackground a.cke_button_off:active {
    height: 16px !important;
} */
/* max-height: 300px; */
.kt-header-menu
  .kt-menu__nav
  .kt-menu__item.kt-menu__item--active-tab
  .kt-menu__submenu,
.kt-header-menu
  .kt-menu__nav
  .kt-menu__item.kt-menu__item--hover
  .kt-menu__submenu {
  max-height: 300px;
  overflow-y: auto;
}

/* .supplier-api-section-box {
    background: white;
    padding: 20px;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #9f9f9f3b;
    box-shadow: 3px 3px 4px 0px #cbc9c96e;
    height: auto;
    width: -moz-fit-content;
    margin: 7px;
    float: right;
} */

input[type="radio"] {
  accent-color: #49691f !important;
}

.supplier-api-section-box {
  background: white;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #9f9f9f3b;
  box-shadow: 3px 3px 4px 0px #cbc9c96e;
  height: auto;
  width: -moz-fit-content;
  margin: 7px;
  float: right;
}

.font-title-supplier-api {
  font-size: 16px !important;
  height: 40px;
}

.warning-color {
  color: #f5a845eb;
}

.succ-color {
  color: #607f37;
}

.gray-color {
  color: #848482;
}

.mar-edit-status {
  margin-right: 2px;
  margin-left: 2px;
}

.border-left-2 {
  border-left: 4px solid;
}

.mt-5per {
  margin-top: 5% !important;
}
.a-like-btn {
  color: #0782c1;
}

.mt-2-5per {
  margin-top: 2.5% !important;
}

.border-left-warning {
  border-left-color: #f5a845eb;
}

.border-left-reject {
  border-left-color: #8a3324;
}

.border-left-success {
  border-left-color: #28a745;
}

.border-left-main-color-1 {
  border-left-color: #607f37 !important;
}

/* .mobile-sub-item {
    max-height: 600px;
    overflow-y: auto;
} */

.border-left-gray {
  border-left-color: #848482 !important;
}

.border-left-danger {
  border-left-color: #dc3545;
}

.deletedIcon:hover {
  color: #49691f !important;
}

.menu-dots-vertical:hover {
  color: #49691f !important;
}

.color-bridcrim {
  color: #969797 !important;
}

.mt-mb-report {
  margin-top: 26px;
  margin-bottom: 7px;
}

.error-alert-icon-api {
  color: #fd9002 !important;
  font-size: 30px !important;
  cursor: pointer;
}

.mt-reachrage-balanceAdmin {
  margin-top: 4.5rem !important;
}

.direction-account-name {
  direction: initial !important;
}

.labelEmpForm {
  font-size: 1rem;
  font-weight: 400;
}

.kt-header-menu-mobile
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__subnav {
  max-height: 600px !important;
  overflow-y: auto !important;
}

/* .kt-header-menu-mobile .kt-menu__nav .kt-menu__inner .kt-menu__content, .kt-header-menu-mobile .kt-menu__nav .kt-menu__submenu .kt-menu__content {
    max-height: 600px !important;
    overflow-y: auto !important;
} */
.new-404-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.kt-header-menu
  .kt-header-menu-mobile
  .sub-menu-item
  .kt-header-menu--layout-tab
  .kt-menu__subnav
  .kt-menu__item
  .kt-menu__item--open
  .kt-menu__inner {
  max-height: 600px !important;
  overflow-y: auto !important;
}

@media (min-width: 576px) {
  .requestdataModal {
    max-width: 400px !important;
  }

  .dev-card-class-order-right {
    padding-bottom: 15px !important;
  }
}
.new-404-page > button {
  margin-left: 80px;
}
@media (max-width: 1024px) {
  .sidebar-modal-view .MuiDrawer-paperAnchorLeft {
    width: 100% !important;
  }
  .c-card-2 {
    width: 100% !important;
  }
  .text-bold-ssm {
    font-weight: bold !important;
  }
  .new-404-page {
    display: flex;
    background: #fff;
    margin: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 20px;
    gap: 10px;
    flex-direction: column;
    border: 2px solid #334f0f;
  }
  .new-404-page > * {
    margin: 0px !important;
    text-align: center !important;
  }
  .new-404-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .action-icon-view {
    display: flex;
  } */
  .form-save-btn,
  .page-action-btn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .sidebar-modal-view .MuiDrawer-paperAnchorRight {
    width: 100% !important;
  }
  .add-order-next-btn-view {
    bottom: 0px !important;
    left: 20% !important;
  }
  .filter-form .form-control,
  .filter-form label {
    font-size: 13px !important;
  }
  body {
    font-size: 16px !important;
  }
  .btn {
    font-size: 1.2rem !important;
  }
  .kt-header-mobile {
    background-color: white !important;
  }

  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-toggler {
    margin: 0px 1.5rem 0 1.5rem !important;
  }
}

@media (min-width: 1025px) {
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-text {
    color: #36491d !important;
  }

  .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__link,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link {
    background-color: #36491d1a;
  }
}

@media (min-width: 1025px) {
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text {
    color: #49691f !important;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #49691f !important;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-text {
    color: #49691f !important;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #49691f !important;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading {
    padding: 20px 40px 10px 30px;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link {
    padding: 5px 30px;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-text {
    color: #49691f !important;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    > .kt-menu__item {
    border-right: 1px solid #f6f6f900;
  }

  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu {
    top: 90%;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5f6281d1;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner {
    width: 190px;
  }

  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav {
    max-height: 600px !important;
    overflow-y: auto !important;
  }
}

@media (min-width: 1025px) {
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #49691f !important;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text {
    color: #49691f !important;
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #49691f !important;
  }

  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav {
    max-height: 600px !important;
    overflow-y: auto !important;
  }
}

@media (min-width: 1125px) {
  .custom-control-label::after {
    top: 0.2rem;
    left: -1.5rem;
  }
}

/* ////////////////////Basic-Media////////////////// ////////*/

/* Breakpoint(X-Small) */

@media only screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .invoiceResponsive {
    text-align: inherit !important;
  }

  .buyCard-Category-CardWidth {
    width: 100%;
  }

  .add-order-next-btn-view {
    position: unset;
    bottom: 2%;
    right: 4%;
    text-align: right;
  }

  .bt-text-align-left {
    text-align: left !important;
  }

  .bt-marginTop {
    margin-top: 10px;
  }

  .gradient-button-view-mobile {
    min-width: 127px !important;
  }

  .margin-Margin-top-Responsive {
    margin-top: 1rem !important;
  }

  .actionDropdownListPadding-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .Switch-padding-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .margin-Bottom-Responsive-8 {
    margin-bottom: 8px !important;
  }

  .financialOperationsMargin {
    margin-top: 10px;
  }

  .table-responsive-row {
    overflow-x: auto;
    width: 100%;
  }

  .table-responsive-row .table-header-row,
  .table-responsive-row .table-body-row {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .dev-card-class-order-right {
    padding-bottom: 15px !important;
  }

  .card-desc-form-view {
    border-bottom: 1px solid #49691f30 !important;
    padding-bottom: 10px !important;

    border-left: 0px solid white !important;
    padding-left: 0px !important;
    min-height: 0vh !important;
    border-right: 0px solid white !important;
    padding-right: 0px !important;
  }

  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav {
    max-height: 600px !important;
    overflow-y: auto !important;
  }
}

/* Breakpoint(Small) */

@media only screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .financialOperationsMargin {
    margin-top: 10px;
  }

  .buyCard-Category-CardWidth {
    width: 50%;
  }

  .add-order-next-btn-view {
    position: absolute;
    bottom: 3.4%;
    right: 4%;
  }

  .invoiceResponsive {
    text-align: inherit !important;
  }

  .bt-text-align-left {
    text-align: left !important;
  }

  .bt-marginTop {
    margin-top: 10px;
  }

  .dev-card-class-order-right {
    padding-bottom: 15px !important;
  }
}

/* Breakpoint(Medium) */

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .invoiceResponsive {
    text-align: inherit !important;
  }

  .buyCard-Category-CardWidth {
    width: 30%;
  }

  .bt-text-align-left {
    text-align: left !important;
  }

  .position-child-square {
    position: absolute;
    bottom: 0;
    width: 96.8%;
  }

  .position-parent-square {
    position: relative;
  }

  /* .mb-0 .mr-1 {
        margin-right: 0 !important; 
    } */
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1024px) {
  .invoiceResponsive {
    text-align: inherit !important;
  }

  .position-child-square {
    position: absolute;
    bottom: 0;
    width: 96.8%;
  }

  .position-parent-square {
    position: relative;
  }
}

/* Breakpoint(Large) */

@media only screen and (min-device-width: 992px) and (max-device-width: 1999px) {
  .position-child-square {
    position: absolute;
    bottom: 0;
    width: 96.8%;
  }

  .position-parent-square {
    position: relative;
  }
}

/* Breakpoint(Extra large) */

@media only screen and (min-device-width: 1200px) and (max-device-width: 1399px) {
  .buyCard-Category-CardWidth {
    width: 20%;
  }

  .position-child-square {
    position: absolute;
    bottom: 0;
    width: 96.8%;
  }

  .position-parent-square {
    position: relative;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 370px) {
  .rep-header-logo {
    max-width: 55% !important;
  }

  .dev-card-class-order-right {
    padding-bottom: 15px !important;
  }

  .mt-10-responsive {
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 371px) and (max-device-width: 500px) {
  .rep-header-logo {
    max-width: 40% !important;
  }

  .dev-card-class-order-right {
    padding-bottom: 15px !important;
  }

  .mt-10-responsive {
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 501px) and (max-device-width: 600px) {
  .rep-header-logo {
    max-width: 30% !important;
  }

  .dev-card-class-order-right {
    padding-bottom: 15px !important;
  }

  .mt-10-responsive {
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 601px) and (max-device-width: 700px) {
  .rep-header-logo {
    max-width: 25% !important;
  }

  .dev-card-class-order-right {
    padding-bottom: 15px !important;
  }

  .mt-10-responsive {
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 1400px) and (max-device-width: 1500px) {
  .add-order-cat-name {
    max-width: 165px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 810px) {
  .tools-box {
    padding: 60px 50px !important;
  }

  .position-child-square {
    position: absolute;
    bottom: 0;
    width: 96.8%;
  }

  .position-parent-square {
    position: relative;
  }
}

@media (max-width: 991px) {
  .status-square-view {
    width: inherit !important;

    max-width: 100%;
  }
  .login-form-view-padding,
  .kt-login.kt-login--v1 .kt-login__wrapper {
    padding-top: 0px;
  }
  #kt_login > div {
    background: white;
  }
  .react-confirm-alert {
    width: 80% !important;
  }
}

/* ////@media-Section-End////// */
